import $ from 'jquery';
import Notice from './notice';

class UseCamera {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.$cancelButton = $('#cancel-photo-option');
    this.$container = $('#submission-form-media-container');
    this.$playBackContainer = $('#camera-playback-container');
    this.$captureButton = $('#capture-photo');
    this.$saveButton = $('#save-photo');
    this.$deleteButton = $('#delete-photo');
    this.video = document.getElementById('camera-video');
    this.canvas = document.getElementById('camera-canvas');
    this.canvasContext = this.canvas.getContext('2d');
    this.mediaDevicesSupported = 'mediaDevices' in navigator;

    if (!this.mediaDevicesSupported || $('.touchevents').length) {
      this.$node.hide();
      return;
    }

    this.eventHandlers();
  }

  eventHandlers() {
    this.$node.on('click', () => this.startStream());
    this.$cancelButton.on('click', () => this.hideCameraVideo());
    this.$captureButton.on('click', () => this.takeSnapShot());
    this.$deleteButton.on('click', () => this.toggleSnapshotVisibility());
    this.$saveButton.on('click', () => this.saveButtonHandler());
  }

  showCameraVideo() {
    this.$container.addClass('camera-video-is-active');
  }

  hideCameraVideo() {
    this.$container.removeClass('camera-video-is-active');
    this.stopStream();
  }

  startStream() {
    const constraints = {
      video: {
        width: { min: 960, ideal: 1920 },
        height: { min: 540, ideal: 1080 },
        aspectRatio: { ideal: 1.7777777778 }
      },
      audio: false
    };

    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        this.video.srcObject = stream;
        this.showCameraVideo();
      })
      .catch((error) => {
        const notice = new Notice;
        notice.notify(error.message);
      });
  }

  takeSnapShot() {
    this.canvasContext.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
    this.toggleSnapshotVisibility();
  }

  toggleSnapshotVisibility() {
    if (this.$playBackContainer.hasClass('snapshot-taken')) {
      this.$playBackContainer.removeClass('snapshot-taken');
    } else {
      this.$playBackContainer.addClass('snapshot-taken');
    }
  }

  saveButtonHandler() {
    // save state for submission form
    $(this.canvas).addClass('image-saved');
    // Add item to filelist 
    if (!$('.snapshot-image').length) {
      $('<li class="snapshot-image">snapshot.jpg</li>').appendTo('#fileslist, #saved-fileslist'); 
    }
    // Hide camera playback
    this.hideCameraVideo();
    this.$container.removeClass('is-active');
    this.toggleSnapshotVisibility();
  }

  stopStream() {
    this.video.srcObject.getVideoTracks().forEach(track => track.stop());
  }
}

export default UseCamera;
