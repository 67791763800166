class TextArea {
  constructor(node) {
    this.$node = node;

    this.adjustHeight();
    this.eventHandlers();
  }

  eventHandlers() {
    this.$node.addEventListener('input', () => this.adjustHeight());
  }

  adjustHeight() {
    this.$node.style.height = '';
    this.$node.style.height = Math.min(this.$node.scrollHeight) + 'px';
  }
}

export default TextArea;
