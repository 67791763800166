import $ from 'jquery';

class Embeds {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;

    this.initInstagramMedia();
  }

  initInstagramMedia() {
    const $instagramMedia = this.$node.find('.instagram-media');

    if ($instagramMedia.length) {
      if ( typeof window.instgrm !== 'undefined' ) {
        window.instgrm.Embeds.process();
      }
    }
  }
}

export default Embeds;
