import $ from 'jquery';

class MoreTextFields {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.$hiddenInput = $('#embed-url');
    this.inputValues = [];

    this.setProperties();
    this.eventHandlers();
    // Get initial value
    const initialHiddenInputValue = this.$hiddenInput.val();
    // Creat an array if a value exists and map over it to create entries
    if (initialHiddenInputValue.length) {
      const initialHiddenInputArray = initialHiddenInputValue.split(',');
      this.inputValues = initialHiddenInputArray;

      if (initialHiddenInputArray.length) {
        initialHiddenInputArray.map((item, index) => {
          if (index === 0) {
            this.$input.val(item);
          } else {
            this.addTextField(item);
          }
        });
      }
    }
  }

  setProperties() {
    this.$textFields = this.$node.find('.text-field');
    this.activeIndex = this.$textFields.length - 1;
    this.$activeTextField = $(this.$textFields[this.activeIndex]);
    this.$input = this.$activeTextField.children('.text-field__input');
    this.$button = this.$activeTextField.children('.text-field__button');
  }

  eventHandlers() {
    this.$input.on('input', () => this.toggleButton());
    this.$input.on('keydown', (event) => this.keydownHandler(event));
    this.$input.on('keyup', (event) => this.keyupHandler(event));
    this.$button.on('click', () => this.addTextField());
  }

  keydownHandler(event) {
    // backspace key pressed
    if (event.keyCode === 8) {
      // Remove field if the input has no value 
      // and there’s multiple fields present 
      if (this.$input.val().length === 0 && this.$textFields.length > 1) {
        this.removeTextField();
      }
    }
  }

  keyupHandler(event) {
    this.inputValues[this.activeIndex] = event.target.value;
    this.$hiddenInput.val(this.inputValues.toString().replace(/,\s*$/, ''));
  }

  toggleButton() {
    if (this.$input.val().length > 0) {
      this.enableButton(true);
    } else {
      this.enableButton(false);
    }
  }

  enableButton(option) {
    if (option) {
      this.$button.css({
        opacity: 1,
        pointerEvents: 'all'
      });
    } else {
      this.$button.css({
        opacity: 0,
        pointerEvents: 'none'
      });
    }
  }

  addTextField(value) {
    // Clone existing textfield with existing 
    // event listeners attached
    const $textFieldClone = this.$activeTextField.clone(true);
    const $label = $textFieldClone.children('.text-field__floating-label');
    const $input = $textFieldClone.children('.text-field__input');
    // Prepare & format new textfield
    $label.remove();
    $input.val(value ? value : '');
    $textFieldClone.addClass('text-field--no-label');
    this.enableButton(false);
    $textFieldClone.appendTo(this.$node);
    // Update state 
    this.update();
    this.enableButton(false);

    if (!value) {
      $input.focus(); 
    }
  }

  removeTextField() {
    // Remove event handlers
    this.$input.off('input').off('keydown');
    this.$button.off('click');
    // Remove text field
    this.$activeTextField.remove();
    // Update active textfield
    this.update();
    // Remove last item in input values array
    this.inputValues.pop();
  }

  update() {
    this.setProperties();
  }
}

export default MoreTextFields;
