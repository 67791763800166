import $ from 'jquery';
import Notice from './notice';

class Subscribe {
  constructor(node) {
    this.$node = $(node);
    this.$email = $('#mce-EMAIL');
    this.$container = this.$node.parents('.consul-form');
    this.$closeButton = $('#close-button');
    this.notice = new Notice;
    this.eventHandlers();
  }

  eventHandlers() {
    this.$node.on('submit', (e) => {
      e.preventDefault();
      if (this.$email.val() === '') {
        this.notice.error('Please enter an email address');
        return;
      }
      this.postFormData();
    });

    this.$closeButton.on('click', () => {
      this.$container.hide();
    });
  }

  postFormData() {
    $.ajax({
      type: 'GET',
      url: this.$node.attr('action').replace('/post?', '/post-json?'),
      data: this.$node.serialize(),
      cache: false,
      dataType: 'jsonp',
      jsonp: 'c',
      contentType: 'application/json; charset=utf-8',
      error: function() {
        this.notice.error('Could not connect to the registration server. Please try again later.');
      },
      success: (data) => {
        if (data.result === 'success') {
          this.notice.success('You’re now subscribed, Thank you!');
        } else {
          if (data.msg.includes('is already subscribed')) {
            this.notice.error('You’re already subscribed');
          } else {
            this.notice.error(data.msg);
          }
        }
        // Reset email field
        this.$email.val('');
      }
    });
  }
}

export default Subscribe;
