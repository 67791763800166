import $ from 'jquery';
import barba from '@barba/core';
import NProgress from 'nprogress';
import slide from '../transitions/slide';
import App from '../app';

export default function () {
  NProgress.configure({ showSpinner: false });
    
  barba.hooks.beforeEnter(({current, next}) => {
    const app = new App();
    app.init(next.container);
    // Remove old class from body
    if (current.namespace !== '') {
      barba.wrapper.classList.remove(current.namespace);
    }
    // Add Class of next page to body
    barba.wrapper.classList.add(next.namespace);
    $(window).scrollTop(0);
  });

  barba.init({
    timeout: 10000,
    preventRunning: true,
    prevent: ({ el }) => el.classList && el.classList.contains('no-barba'),
    transitions: [{
      name: 'panel-transition',
      from: {
        // define a custom rule based on the trigger class
        custom: ({ trigger }) => {
          return trigger.classList && trigger.classList.contains('use-panel-transition');
        },
      },
      leave: ({ trigger }) => {
        barba.wrapper.classList.add('is-animating');
        const $animatingPanels = $(trigger).parent('.panel').nextAll();

        return Promise.all([
          slide($animatingPanels, 400, 0, window.innerHeight)
        ]); 
      },
      enter: ({ next, trigger }) => {
        const triggerIndex = $(trigger).parent('.panel').index();
        let $animatingPanels;
        let offsetTop;

        if (triggerIndex) {
          $animatingPanels = $(next.container).find('.panel').eq(0).nextAll();
          offsetTop = $(next.container).find('.panel').eq(triggerIndex).offset().top;
          
          Promise.all([
            slide($animatingPanels, 100, -offsetTop, 0, 'easeOutQuad')
          ]);
        } else {
          $animatingPanels = $(next.container).find('.panel').eq(triggerIndex).nextAll();
          offsetTop = $(next.container).find('.panel').eq(triggerIndex + 1).offset().top;
          
          Promise.all([
            slide($animatingPanels, 100, -offsetTop, 0, 'easeOutQuad')
          ]);
        }

        barba.wrapper.classList.remove('is-animating');
      }
    }, {
      name: 'home-transition',
      from: {
        // define a custom rule based on the trigger class
        custom: ({ trigger }) => {
          return trigger.classList && trigger.classList.contains('use-home-transition');
        },
      },
      leave: ({ trigger }) => {
        barba.wrapper.classList.add('is-animating');
        const $movingUpPanel = $(trigger).parent('.panel');
        const offsetTop = $movingUpPanel.offset().top;
        const $movingDownPanels = $(trigger).parent('.panel').siblings();

        return Promise.all([
          slide($movingDownPanels, 200, 0, $(trigger).height() * 3),
          slide($movingUpPanel, 400, 0, -offsetTop, 'easeOutQuad')
        ]); 
      },
      enter: ({ next, trigger }) => {
        const triggerIndex = $(trigger).parent('.panel').index();
        let $animatingPanels;
        let offsetTop;
        
        if (triggerIndex) {
          $animatingPanels = $(next.container).find('.panel').eq(0).nextAll();
          offsetTop = $(next.container).find('.panel').eq(triggerIndex).offset().top;
          
          Promise.all([
            slide($animatingPanels, 100, -offsetTop, 0, 'easeOutQuad')
          ]);
        } else {
          $animatingPanels = $(next.container).find('.panel').eq(triggerIndex).nextAll();
          offsetTop = $(next.container).find('.panel').eq(triggerIndex + 1).offset().top;
          
          Promise.all([
            slide($animatingPanels, 100, -offsetTop, 0, 'easeOutQuad')
          ]);
        }

        barba.wrapper.classList.remove('is-animating');
      }
    }, {
      name: 'arrival-transition',
      from: {
        // define a custom rule based on the trigger class
        custom: ({ trigger }) => {
          return trigger.classList && trigger.classList.contains('use-arrival-transition');
        },
      },
      enter: ({ next }) => {
        const panelsLength = $(next.container).find('.panel').length;
        const $animatingPanels = $(next.container).find('.panel').eq(0).nextAll();
        const offsetTop = $(next.container).find('.panel').eq(panelsLength - 1).offset().top;
        
        return Promise.all([
          slide($animatingPanels, 100, -offsetTop, 0)
        ]);
      }
    }]
  });
}
