import $ from 'jquery';

class CopyToClipBoard {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;

    this.eventhandlers();
  }

  eventhandlers() {
    this.$node.on('click', function() {
      const $this = $(this);
      const $input = $this.children('input');
      const $notification = $this.parents('.link-parent');
      // Select value from input (url)
      $input.select();
      // Copy value to clipboard
      document.execCommand('copy');
      // Notify copy
      $notification.addClass('link-copied');

      const notifyDuration = setTimeout(() => {
        $notification.removeClass('link-copied');
        clearTimeout(notifyDuration);
      }, 4000);
    });
  }
}

export default CopyToClipBoard;
