import $ from 'jquery';

class userLocationReport {
  constructor(node) {
    const $node = $(node);
    this.$time = $node.find('.user-location-report__time');
    this.$date = $node.find('.user-location-report__date');
    this.$timezone = $node.find('.user-location-report__timezone');
    this.date = new Date();

    this.intervalID = setInterval(() => this.clock(), 1000);
    this.displayDate();
    this.displayTimeZoneOffset();
    $node.addClass('data-ready');
  }

  displayDate() {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    this.$date.text(`${days[this.date.getDay()]}, ${this.date.getDate()} ${months[this.date.getMonth()]} ${this.date.getFullYear()}`);
  }

  displayTimeZoneOffset() {
    let timezoneOffset = Math.floor(this.date.getTimezoneOffset() / 60);
    timezoneOffset = Math.sign(timezoneOffset) === -1 ? timezoneOffset.toString().replace('-', '+') : timezoneOffset.toString().replace('+', '-');
    this.$timezone.text(`GMT ${timezoneOffset}`);
  }

  clock() {
    this.date = new Date();
    let s = this.date.getSeconds();
    let m = this.date.getMinutes();
    let h = this.date.getHours();
    this.$time.text(`${('0' + h).slice(-2)}:${('0' + m).slice(-2)}:${('0' + s).slice(-2)}`);

    if (window.location.pathname !== '/') {
      clearInterval(this.intervalID);
      this.intervalID = null;
    }
  }
}

export default userLocationReport;
