import $ from 'jquery';
import barba from '@barba/core';
import Notice from './notice';

class Filter {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.item = this.$node.find('.filter-item');
    this.toggle = this.$node.find('.filter__heading');
    this.posts = $('.contributor-module');
    this.eventhandlers();
    this.query = new URLSearchParams(window.location.search);
  }

  eventhandlers() {
    const filter = this;
    // Category item (sort by label) click event
    this.item.on('click', function(event) {
      event.preventDefault();
      event.stopPropagation();
      const $this = $(this);
      const filterType = $this.data('filter-type');
      const id = $this.data('id');
      // return early if this item is disabled
      if ($this.hasClass('filter__item--disabled')) {
        // Notify the issue
        const notice = new Notice;
        // check if item is category item
        if ($this.hasClass('filter__item--category')) {
          notice.notify(`${id} has been disabled`);
        } else if ($this.hasClass('filter__item--client'))  {
          notice.notify(`There aren’t any projects in this category for ${id}`);
        } else {
          notice.notify(`${id} does not have any posts matching the criteria`);
        }
        // return early
        return;
      }
      // Unlike other filterable categories, authors have 
      // multiple selection enabled
      if (filterType === 'author') {
        let authors = filter.query.get(filterType);
        if (authors) {
          authors = authors.split(',');
          const index = authors.indexOf(id);
          // remove author if already in array
          // else add it
          if (index > -1) {
            authors.splice(index, 1);
            $this.removeClass('is-active');
          } else {
            $this.addClass('is-active');
            authors.push(id);
          }
          // Update query
          if (authors.length) {
            filter.query.set(filterType, authors.toString()); 
          } else {
            filter.query.delete(filterType);
          }
        } else {
          filter.query.set(filterType, id);
        }
      } else if (id === 'all') {
        $(`filter__item--${filterType}`).removeClass('is-active');
        $this.addClass('is-active');
        filter.query.delete(filterType);
      } else if (filter.query.get(filterType) === id) {
        $this.removeClass('is-active');
        filter.query.delete(filterType);
      } else {
        $(`filter__item--${filterType}`).removeClass('is-active');
        $this.addClass('is-active');
        filter.query.set(filterType, id);
        // always delete contributor from query
        if (filterType === 'category') {
          filter.query.delete('contributor');
          filter.query.delete('client');
        }
      }
      // Execute
      filter.execute();
    });

    this.toggle.on('click', function() {
      const $this = $(this);
      const $container = $this.parents('.filter__category');

      if ($container.hasClass('is-open')) {
        $container.removeClass('is-open');
      } else {
        $container.addClass('is-open');
      }
    });
  }

  execute() {
    const queryString = this.query.toString().length ? `?${this.query.toString()}` : '';
    barba.go(`${window.location.href.split('?')[0]}${queryString}`);
  }
}

export default Filter;
