import $ from 'jquery';
import NProgress from 'nprogress';
import Notice from './notice';

class SubmitFormData {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.canvas = document.getElementById('camera-canvas');
    this.$formWrapper = $('#submission-form-wrapper');
    this.notice = new Notice;

    this.eventHandlers();
  }

  eventHandlers() {
    this.$node.on('submit', (event) => this.submitHandler(event));
  }

  submitHandler(event) {
    event.preventDefault();
    // Some basic value checks first
    if ($('#text-entry').val() === '' && $('#embed-urls').val() === '' && $('#files')[0].files.length === 0) {
      this.notice.notify('Please submit some content — text, files or an embed URL');
      return;
    }
    // Start the progress bar
    NProgress.start();
    // Form data
    const data = new FormData(this.$node[0]);
    // If snapshot has been saved append that 
    // to forma data before posting data
    if ($(this.canvas).hasClass('image-saved')) {
      this.canvas.toBlob((blob) => {
        // Append blob to form data
        data.append('files[]', blob, 'snapshot.jpg');
        // Post data
        this.execute(data);
      }, 'image/jpeg', 1);
    } else {
      // Post data
      this.execute(data);
    }
  }

  execute(data) {
    const formAction = this.$node.attr('action');

    $.ajax({
      url: formAction,
      data: data,
      cache: false,
      contentType: false,
      processData: false,
      method: 'POST',
      success: (response) => {
        if (response.status === 'success') {
          NProgress.done();
          this.$formWrapper.addClass('form-posted');
        } else {
          const $filelist = $('#fileslist, #saved-fileslist');
          NProgress.done();
          // remove previous error notes
          $('.error-note').remove();
          // Reset files uploader UI state
          if ($filelist.children('li').length) {
            $filelist.empty();
            $('#media-uploader-button').addClass('error'); 
            $('#exit-file-uploader-button').addClass('button--hidden');
          }
          // format & display errors
          const errors = response.errors;
          Object.keys(errors).map(key => {
            const $formRow = $(`[name='${key}']`).parents('.submission-form__row');
            $formRow.addClass('error');
            $(`<p class='error-note'>${errors[key]}</p>`).appendTo($formRow);
          });

          if (errors.csrf) {
            // notify user of errors
            this.notice.notify(response.errors.csrf);
          }
        }
      }
    });
  }
}

export default SubmitFormData;
