import $ from 'jquery';
import pageTransitions from './components/pageTransitions';
import TextField from './components/textField';
import MoreTextFields from './components/moreTextFields';
import TextArea from './components/textArea';
import FormFiles from './components/formFiles';
import UseCamera from './components/useCamera';
import SubmitFormData from './components/submitFormData';
import ImageSlider from './components/imageSlider';
import VideoPlayer from './components/videoPlayer';
import AudioPlayer from './components/audioPlayer';
import FilterSlider from './components/filterSlider';
import ResponseItem from './components/responseItem';
import Embeds from './components/embeds';
import CopyToClipBoard from './components/copyToClipBoard';
import Filter from './components/filter';
import Notice from './components/notice';
import userLocationReport from './components/userLocationReport';
import displayRespondForm from './components/displayRespondForm';
import ScrollToAnchor from './components/scrollToAnchor';
import Subscribe from './components/subscribe';
import InfiniteScroll from './components/infiniteScroll';
import StickyLogo from './components/stickyLogo';

export default class App {
  constructor() {
    this.components = {
      textField: TextField,
      moreTextFields: MoreTextFields,
      textArea: TextArea,
      formFiles: FormFiles,
      useCamera: UseCamera,
      submitFormData: SubmitFormData,
      imageSlider: ImageSlider,
      videoPlayer: VideoPlayer,
      audioPlayer: AudioPlayer,
      filterSlider: FilterSlider,
      responseItem: ResponseItem,
      embeds: Embeds,
      copyToClipboard: CopyToClipBoard,
      filter: Filter,
      notice: Notice,
      userLocationReport: userLocationReport,
      displayRespondForm: displayRespondForm,
      scrollToAnchor: ScrollToAnchor,
      subscribe: Subscribe,
      infiniteScroll: InfiniteScroll,
      stickyLogo: StickyLogo
    };
  }

  init(container) {
    const components = this.components;

    $(container).find('[data-component]').each(function() {
      const node = this;
      $(node).data('component').split(/\s+/).forEach((componentName) => {
        const component = components[componentName];
        if (component) {
          new component(node);
        }
      });
    });
  }
}

$(function() {
  // Instantiate Barba.js
  pageTransitions();
});

