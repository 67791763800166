window.VIDEOJS_NO_DYNAMIC_STYLE = true;
import videojs from 'video.js';

class AudioPlayer {
  constructor(node) {
    this.options = {
      controls: true,
      autoplay: false,
      preload: false,
      controlBar: {
        children: [
          'playToggle',
          'remainingTimeDisplay',
          'progressControl',
          'volumePanel',
        ]
      },
    };

    this.player = videojs(node, this.options, function() {
      this.addClass('vjs-has-started');
    });
    // this.player.removeChild('BigPlayButton');

    // Create a track object.
    const track = new videojs.AudioTrack({
      id: 'my-spanish-audio-track',
      kind: 'translation',
      label: 'Spanish',
      language: 'es'
    });

    // Add the track to the player's audio track list.
    this.player.audioTracks().addTrack(track);
  }
}

export default AudioPlayer;
