import $ from 'jquery';
import Flickity from 'flickity';

class ImageSlider {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    
    this.slider = new Flickity(node, {
      freeScroll: true,
      pageDots: false,
      wrapAround: true,
      lazyLoad: 2,
    });
  }
}

export default ImageSlider;
