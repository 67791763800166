import $ from 'jquery';
import Flickity from 'flickity';

class FilterSlider {
  constructor(node) {
    const $node = $(node);
    this.node = node;
    this.$node = $node;
    this.flickityEnabled = false;

    if (this.$node.hasClass('filter__slider--sort')) {
      if ($(window).width() > 768) {
        this.initFlickity();
        this.flickityEnabled = true;
      }
    } else {
      this.initFlickity();
    }

    this.eventHandlers();
  }

  eventHandlers() {
    $(window).on('resize', () => this.resizeHandler());
  }

  resizeHandler() {
    if (this.$node.hasClass('filter__slider--sort')) {
      if ($(window).width() < 768) {
        if (this.flickityEnabled) {
          this.slider.destroy();
          this.flickityEnabled = false;
        } 
      } else {
        if (!this.flickityEnabled) {
          this.initFlickity();
          this.flickityEnabled = true;
        }
      }
    }
  }

  initFlickity() {
    this.slider = new Flickity(this.node, {
      freeScroll: true,
      prevNextButtons: false,
      pageDots: false,
      contain: true,
      cellAlign: 'left'
    });
  }
}

export default FilterSlider;
