import InfiniteAjaxScroll from '@webcreate/infinite-ajax-scroll';

class InfiniteScroll {
  constructor(node) {
    this.ias = new InfiniteAjaxScroll(node, {
      logger: false,
      item: '.post-item',
      next: '.MarkupPagerNavNext a',
      pagination: '.pagination',
      negativeMargin: -600
    });
  }
}

export default InfiniteScroll;
