import $ from 'jquery';

class ScrollToAnchor {
  constructor () {
    // Automatically open response if id is URL
    if (window.location.hash) {
      const hash = window.location.hash.substring(1);

      if ($(`#${hash}`).length) {
        window.scroll({
          top: $(`#${hash}`).offset().top - 50
        });

        const delay = setTimeout(() => {
          this.scrollTo(hash);
          clearTimeout(delay);
        }, 500); 
      }
    }
  }

  scrollTo(hash) {
    window.scroll({
      behavior: 'smooth',
      top: $(`#${hash}`).offset().top - 50
    });
  }
}

export default ScrollToAnchor;
