window.VIDEOJS_NO_DYNAMIC_STYLE = true;
import videojs from 'video.js';

class VideoPlayer {
  constructor(node) {
    this.options = {
      controls: true,
      autoplay: false,
      preload: false,
      controlBar: {
        children: [
          'playToggle',
          'remainingTimeDisplay',
          'progressControl',
          'volumePanel',
          'fullscreenToggle'
        ]
      },
    };

    this.player = videojs(node, this.options);
    this.player.bigPlayButton.controlText('Play');
  }
}

export default VideoPlayer;
