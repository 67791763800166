import $ from 'jquery';

class ResponseItem {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.button = this.$node.find('.response__icon--toggle');

    this.eventHandlers();
    // Automatically open response if id is URL
    if (window.location.hash) {
      const hash = window.location.hash.substring(1);
      if (hash === this.$node.attr('id')) {
        this.open();
      }
    }
  }

  eventHandlers() {
    this.button.on('click', () => this.clickHandler());
  }

  clickHandler() {
    if (this.$node.hasClass('is-open')) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.$node.addClass('is-open').siblings().removeClass('is-open');
    window.scroll({
      top: this.$node.offset().top - 100
    });
    // Delay scroll to selected response
    const updateScrollPos = setTimeout(() => {
      window.scroll({
        behavior: 'smooth',
        top: this.$node.offset().top - 100
      });
      clearTimeout(updateScrollPos);
    }, 500);
  }

  close() {
    this.$node.removeClass('is-open');
  }
}

export default ResponseItem;
