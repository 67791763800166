import $ from 'jquery';

class StickyLogo {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.triggerOffset = $('#sticky-trigger').offset().top;
    
    setTimeout(() => {
      this.triggerOffset = $('#sticky-trigger').offset().top;

      $('.panel.active-panel').each(function() {
        $(this).removeAttr('style');
      });
    }, 500);

    this.eventhandlers();
  }

  eventhandlers() {
    const that = this;

    $(window).on('scroll', function() {
      const scrollTop = $(this).scrollTop();

      if (scrollTop >= that.triggerOffset) {
        that.$node.addClass('is-fixed');
      } else {
        that.$node.removeClass('is-fixed');
      }
    });
  }
}

export default StickyLogo;
