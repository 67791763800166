import $ from 'jquery';

class displayRespondForm {
  constructor(node) {
    this.$node = $(node);
    this.$container = $('#submission-form-media-container');
    this.$overlay = $('#form-overlay');
    this.isActive = false;
    this.exitButton = $('.exit-2-contributor-page');
    this.video = document.getElementById('camera-video');
    this.eventHandlers();
  }

  eventHandlers() {
    this.$node.on('click', () => {

      if (this.isActive) {
        this.close();
      } else {
        this.open();
      }

      this.isActive = !this.isActive;
    });

    this.exitButton.on('click', (event) => {
      event.preventDefault();
      
      this.close();
      this.isActive = false;
    });
  }

  open() {
    this.$overlay.addClass('is-active');
  }

  close() {
    this.$overlay.removeClass('is-active');
    this.$container.removeClass('camera-video-is-active');

    if (this.video.srcObject) {
      this.video.srcObject.getVideoTracks().forEach(track => track.stop()); 
    }
  }
}

export default displayRespondForm;
