import $ from 'jquery';
import barba from '@barba/core';

class Notice {
  constructor() {
    const $node = $('#notice');
    this.$node = $node;

    barba.hooks.after(() => {
      if ($('#notice-message').length) {
        this.notify($('#notice-message').data('message'));
      }
    });
  }

  showNotice(message) {
    this.$node.addClass('is-active').html(`<p>${message}</p>`);
    // Hide notice after 2s
    const timeout = setTimeout(() => {
      this.$node.removeClass('is-active');
      clearTimeout(timeout);
    }, 2000);
  }

  notify(message) {
    // Reset notice status
    this.$node.removeClass('notice--error notice--success');
    // Show notice
    this.showNotice(message);
  }

  error(message) {
    this.$node.removeClass('notice--success');
    this.$node.addClass('notice--error');
    // Show notice
    this.showNotice(message);
  }

  success(message) {
    this.$node.removeClass('notice--error');
    this.$node.addClass('notice--success');
    // Show notice
    this.showNotice(message);
  }
}

export default Notice;
