import $ from 'jquery';

class FormFiles {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.$container = this.$node.parents('.submission-form__media');
    this.fileslist = $('#fileslist, #saved-fileslist');
    this.$button = $('#media-uploader-button');
    this.$exit = $('.exit-file-uploader-button');
    this.video = document.getElementById('camera-video');

    this.eventHandlers();
  }

  eventHandlers() {
    this.$node.on('change', (event) => this.onChangeHandler(event));
    this.$button.on('click', () => this.onClickHandler());
    this.$exit.on('click', () => this.onClickHandler());
  }

  onChangeHandler(event) {
    this.displayFileNames(event.target.files);
  }

  displayFileNames(files) {
    const $listItems = this.fileslist.children('li');
    // Empty fileslist if not snapshot item
    $listItems.each(function() {
      const $this = $(this);

      if ($this.text() !== 'snapshot.jpg') {
        $this.remove();
      }
    });
    // Add new items
    Array.from(files).forEach(file => $(`<li>${file.name}</li>`).appendTo(this.fileslist));
    // Show Save button and attach eventHandler
    $('#exit-file-uploader-button').children('button').html('<span>Save</span>');
  }

  onClickHandler() {
    if (this.$container.hasClass('is-active')) {
      this.$container.removeClass('is-active');
      this.$container.removeClass('camera-video-is-active');

      if (this.video.srcObject) {
        this.video.srcObject.getVideoTracks().forEach(track => track.stop()); 
      }

      $('#use-camera').html('<span>Use camera</span>');
    } else {
      this.$container.addClass('is-active');
      this.$button.removeClass('error');
    }
  }
}

export default FormFiles;
