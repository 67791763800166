import $ from 'jquery';
import validate from 'validate.js';

class TextField {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.$input = this.$node.find('.text-field__input');
    this.validationErrors = {};

    this.eventHandlers();
  }

  eventHandlers() {
    this.$input.on('focus', () => this.textFieldStateHandler());
    this.$input.on('blur', () => this.blurHandler());
  }

  blurHandler() {
    const $parent = this.$node.parent('.submission-form__row');
    const $errorNote = $parent.find('.error-note');
    // active state handler
    this.textFieldStateHandler();
    // validation for required and email fields
    switch (this.$input.attr('name')) {
      case 'email':
        this.validationErrors = {type: 'email', message: validate.single(this.$input.val(), {presence: true, email: {message: 'Email invalid'}})};
        break;
      case 'fullname':
        this.validationErrors = {type: 'fullname', message: validate.single(this.$input.val(), {presence: true, length: {minimum: 1, message: 'Fullname required'}})};
        break;
      case 'city':
        this.validationErrors = {type: 'city', message: validate.single(this.$input.val(), {presence: true, length: {minimum: 1, message: 'City required'}})};
        break;
      case 'submissionTitle':
        this.validationErrors = {type: 'submissionTitle', message: validate.single(this.$input.val(), {presence: true, length: {minimum: 1, message: 'Title required'}})};
        break;
    }

    if (this.validationErrors.message) {
      $parent.addClass('error');
      $(`<p class='error-note'>${this.validationErrors.message}</p>`).appendTo($parent);
    } else {
      $errorNote.remove();
      $parent.removeClass('error');
    }
  }

  textFieldStateHandler() {
    if (this.$node.hasClass('is-active')) {
      if (this.$input.val() === '') {
        this.$node.removeClass('is-active');
      }
    } else {
      this.$node.addClass('is-active');
    }
  }
}

export default TextField;
